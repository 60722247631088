var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('heads'),_c('div',{class:[
      _vm.$route.meta.id == 10 ? ' cen' : ' ',
      _vm.$route.meta.id == 21 ? ' cent' : '',
      _vm.$route.meta.id == 17 ? ' cens' : '',
    ],style:({
      backgroundImage: 'url(' + require('@/assets/' + _vm.backgroud) + ')',
    })},[_c('div',{staticClass:"cen_box"},[_c('div',{class:[
          _vm.$route.meta.id == 17 ? 'cen-topst' : 'cen-top',
          _vm.$route.meta.id == 21 ? ' anst' : ' ',
        ]},_vm._l((_vm.videosanst),function(item,index){return _c('div',{key:index,staticClass:"link-wrap"},[_c('router-link',{staticClass:"i-item",attrs:{"to":{
              path: _vm.pathUrl,
              query: { id: item.id },
            }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)}),0)])]),_c('foot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }